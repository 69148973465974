<template>
  <div class="dev-process">
    <BannerDevProcess></BannerDevProcess>
    <div class="dev-process__title">
      <h1>企业发展历史</h1>
    </div>
    <div class="dev-process__container">
      <!-- <img class="dev-process__bg" src="@/assets/images/dev-process-bg.png" alt="bg"> -->
      <div class="dev-process__con">
        <!-- <img src="@/assets/images/dev-process.png" alt="process"> -->
        <ul>
          <li>
            <span>2016年3月</span>
            <span>icon</span>
            <span>仰读教育科技有限公司在南京成立。</span>
          </li>
          <li>
            <span>2016年8月</span>
            <span>icon</span>
            <span>自考树微信公众号正式上线。</span>
          </li>
          <li>
            <span>2017年3月</span>
            <span>icon</span>
            <span>自考树第一个产品“21天训练营”正式上线。</span>
          </li>
          <li>
            <span>2017年11月</span>
            <span>icon</span>
            <span>Bella英语二课程正式上线。课程被考生评为“自考行业里最好的英语课”。</span>
          </li>
          <li>
            <span>2018年6月</span>
            <span>icon</span>
            <span>自考树课堂小程序正式上线。</span>
          </li>
          <li>
            <span>2018年9月</span>
            <span>icon</span>
            <span>国内第一本自考英语单词书—《21天搞定英语二单词》正式出版。</span>
          </li>
          <li>
            <span>2018年12月</span>
            <span>icon</span>
            <span>公司获得天使轮融资。</span>
          </li>
          <li>
            <span>2019年7月</span>
            <span>icon</span>
            <span>自考树题库产品正式上线。</span>
          </li>
          <li>
            <span>2019年9月</span>
            <span>icon</span>
            <span>自考树押题卷产品正式上线。</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import BannerDevProcess from "@/components/banner/BannerDevProcess.vue"
  export default {
    name: 'DevProcess',
    components: {
      BannerDevProcess,
    }
  }
</script>

<style lang="scss">
  .dev-process {
    padding-bottom: 5%;

    &__title {
      margin: 0 auto;
      padding-top: 90px;
      max-width: 1140px;
      font-size: 30px;

      h1 {
        padding: 0 10px;
      }
    }

    &__container {
      padding: 55px 0;
      // text-align: right;
      // position: relative;
      // .dev-process__bg {
      //   max-width: 1130px;
      // }
      background: url('~@/assets/images/dev-process-bg.png') no-repeat center right;
      background-size: 58% auto;
    }

    &__con {
      margin: 0 auto;
      max-width: 1140px;
      text-align: left;

      // position: absolute;
      // top: 50%;
      // transform: translateY(-50%);
      background-color: rgba(255, 255, 255, 0.8);

      img {
        max-width: 1100px;
        width: 100%;
      }

      ul {
        padding: 4% 0;
        li {
          span {
            padding: 1em 10px;
            display: inline-block;
            font-size: 18px;
          }

          span:nth-child(1) {
            width: 21%;
            min-width: 8em;
            text-align: right;
            color: #666;
          }

          span:nth-child(2) {
            width: 62px;
            position: relative;
            color: transparent;
            background: linear-gradient(#E9E9E9, #E9E9E9) no-repeat;
            background-size: 2px 100%;
            background-position: center;

            &:after {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              content: '';
              width: 1em;
              height: 1em;
              border-radius: 50%;
              background-color: #E9E9E9;
            }
          }

          span:nth-child(3) {
            color: #333;
            font-weight: bold;
          }
        }
      }
    }
  }
</style>
