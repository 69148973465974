<template>
  <div class="banner-container">
    <img src="@/assets/images/banner/banner-dev-process-bg.png" alt="banner">
    <div class="container-of">

    </div>
  </div>
</template>

<script>
  export default {
    name: 'BannerDevProcess'
  }
</script>

<style>
</style>
